module Sxc.ChatPreview.WebAPI
{
	"use strict";

	let _tokenID : string = null;
	let _webID : string | number = null;
	let _url : string = null;

	export function init(baseURL : string, tokenID : string, webID : string | number)
	{
		_url = baseURL;
		_tokenID = tokenID;
		_webID = webID;
		console.log("WebAPI2", "Initialized", _url, _tokenID, _webID);
	}

	export function get(method : string, data : {[key : string] : any})
	{
		return call(method, data, "GET");
	}

	export function post(method : string, data : {[key : string] : any})
	{
		return call(method, data, "POST");
	}

	export function call(call : string, data : {[key : string] : any}, method : "GET"|"POST" = "POST")
	{
		if (!_url) {
			throw new Error("[WebAPI2] URL Not set");
		}

		console.log("WebAPI2", "Request:", call, data);

		return new Promise((resolve, reject) =>
		{
			$.ajax({
				type: method,
				url: `${_url}${call}?` + $.param({ tokenID: _tokenID }),
				data: data,
				crossDomain: true,
				dataType: "text",
				success: (response : string) =>
				{
					try {
						response = $.parseJSON(response);
						console.debug("WebAPI", "Response", response);
						resolve(response);

					} catch (e) {
						console.error("WebAPI2", "Failed to parse JSON from calling '" + call + "' with data " + JSON.stringify(data) + " Response is:", response);
						console.error("WebAPI2", "Error: ", e);
						reject("Failed to parse JSON from calling '" + call);
					}
				},
				error: (...args : any[]) =>
				{
					console.error("Failed to call WebAPI2", ...args);
					reject();
				}
			});
		});
	}
}
