/// <reference path="interfaces.ts" />

enum SxcChatPreviewState
{
	idle, connecting, playing
}

declare let Hls: any;

class SxcChatPreview
{
	TAG = "[SxcChatPreview]";
	state: SxcChatPreviewState;
	private video: HTMLVideoElement;
	private ignoreReq: boolean = false;
	private hls: any;
	private volume: number = 0;

	constructor(private root: HTMLElement, private params: SxcChatPreviewParams)
	{
		Sxc.ChatPreview.WebAPI.init(params.apiURL, "guest", 0);
		this.state = SxcChatPreviewState.idle;
	}

	public setVolume(volume: number)
	{
		this.volume = volume;
		if (this.video) {
			this.video.volume = volume;
		}
	}

	public showRoom(roomID: string, onReady: (r) => void)
	{
		console.log(this.TAG, "Requesting room", roomID);

		if (this.state !== SxcChatPreviewState.idle) {
			console.log(this.TAG, "Not idle, cancelling first...");
			this.cancel(() => {
				this.showRoom(roomID, onReady);
			});
			return;
		}

		this.ignoreReq = false;
		this.state = SxcChatPreviewState.connecting;

		this.getRoomData(roomID, (status, address) =>
		{
			if (this.ignoreReq) {
				return;
			}

			this.hasRoom(status, address, onReady)
		});
	}

	public showRoomStream(roomID : string, status : string, address : string, onReady : (r) => void)
	{
		this.hasRoom(status, address, onReady);
	}

	private getRoomData(roomID: string, onReady: (mode, stream) => void)
	{
		console.log(this.TAG, "getRoomData", "Loading room data", roomID);

		Sxc.ChatPreview.WebAPI.get("chatPreview/getRoom", {roomID})
			.then((r: any) => {
				console.log(this.TAG, "getRoomData", "Response is", r);

				if (!r || !("success" in r) || !r.success) {
					onReady("error", null);
					return;
				}

				let data: RoomData = r.data;

				if (data.onlineStatus !== "free") {
					onReady(data.onlineStatus, null);
					return;
				}

				let hasStream = "onlineParams" in data && "modeSpecific" in data.onlineParams && "main" in data.onlineParams.modeSpecific;

				let stream = hasStream
					?
					data.onlineParams.modeSpecific.main.hls.address.replace(":1935/", ":444/").replace("http://", "https://")
					:
					null;

				onReady("free", stream);
			})
			.catch((x) => {
				console.log(this.TAG, "getRoomData", "Req error", x);
				onReady("error", null);
			})
	}

	private hasRoom(status, address, onReady : (r) => void)
	{
		if (status === "free")
		{
			this.state = SxcChatPreviewState.playing;
			this.video = document.createElement("video");

			this.video.style.position = "absolute";
			this.video.style.top = "0px";
			this.video.style.left = "0px";
			this.video.style.width = "100%";
			this.video.style.height = "100%";
			this.video.style["objectFit"] = "cover";

			this.root.appendChild(this.video);

			this.video.volume = this.volume;

			this.hls = new Hls();
			this.hls.loadSource(address);
			this.hls.attachMedia(this.video);

			this.hls.on(Hls.Events.ERROR, (e) => {
				console.log(this.TAG, "HLS Error", e);
				this.cancel(() => {
					onReady("end");
				})
			});

			this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
				console.log(this.TAG, "Playing video");
				this.video.play();
				console.log(this.TAG, "Responding with:", "free");
				onReady("free");
			});

			this.video.onended = (e) => {
				console.log(this.TAG, "Playback ended", e);
				this.cancel(() => {
					onReady("end");
				})
			};

			this.video.onerror = (e) => {
				console.log(this.TAG, "Playback error", e);
				this.cancel(() => {
					onReady("end");
				})
			}
		}
		else {
			this.state = SxcChatPreviewState.idle;
			onReady(status);
		}
	}

	public cancel(onReady: () => void)
	{
		console.log(this.TAG, "Cancel requested...");
		this.ignoreReq = true;

		if (this.hls) {
			console.log(this.TAG, " - Destroying HLS Stream...");
			this.hls.destroy();
			this.hls = null;
		}

		if (this.state === SxcChatPreviewState.playing) {
			console.log(this.TAG, " - Removing content...");
			this.video = null;
			this.root.innerHTML = "";
		}

		this.state = SxcChatPreviewState.idle;

		onReady();
	}
}
